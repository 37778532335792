<template>
  <Transition name="imageappear" appear>
    <img
      v-if="srcset?.length > 0"
      :srcset="srcset.map((s) => `${s.src} ${s.options.size[0]}w`).join(', ')"
      :sizes="sizes"
      :alt="props.alt"
      @load="emits('load', $event)"
    />
  </Transition>
</template>

<script setup lang="ts">
import api from "@virgodev/bazaar/functions/api";
import { ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    src: string;
    alt: string;
    alias?: string;
    sizes?: string;
  }>(),
  {
    alias: "square",
    sizes: "500px;",
  },
);

const emits = defineEmits<{
  load: [LoadEvent];
}>();

const srcset = ref([]);

watch(
  props,
  async () => {
    const response = await api({
      url: "thumbnail/",
      params: { image: props.src, alias: props.alias },
      method: "GET",
      options: {
        // cache: 'no-cache',
        cache: "default",
      },
    });
    if (response.ok) {
      srcset.value = response.body;
    }
  },
  { immediate: true },
);
</script>

<style scoped>
img {
  max-width: 100%;
}
img.loader {
  object-fit: contain;
  height: 64px;
  width: 64px;
  margin: auto;
}

.imageappear-enter-active {
  transition: opacity 500ms;
}
.imageappear-leave-active {
  transition: none;
}
/* .imageappear-enter, */
.imageappear-enter-from,
.imageappear-leave-to {
  opacity: 0;
}
</style>
